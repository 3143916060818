<template>
  <div class="content">
        <div class="flex btn-group-title">
      <img src="@/assets/home/Group 402.png" class="imgGroup" />
      <div class="group-title">{{ dataList.zbmc }}</div>
    </div>
    <div class="flex">
      <div :id="`echarts-pie${this.id}`" class="echarts-pie"></div>
      <div>
        <div class="star flex">
          <img src="@/assets/home/s.png" alt="" class="img" />
          <div class="star-text">目标值 {{ dataList.zrdwdlrwmbz }}</div>
        </div>
        <div class="count flex">
          <div class="block"></div>
          <div class="count-text">完成数</div>
          <div class="count-value">{{ dataList.zrdwdlrwwcz }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: function () {
        return {};
      },
    },
    id: {
      type: Number,
      default:null
    },
  },
  data() {
    return {
      zrdwdlrwwcz:0,
      zrdwdlrwmbz:0
    };
  },
  methods: {
    drawEcharts() {
      this.zrdwdlrwwcz = this.dataList.zrdwdlrwwcz
      this.zrdwdlrwmbz = this.dataList.zrdwdlrwmbz
      var chartDom = document.getElementById(`echarts-pie${this.id}`);
      var myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "",
            type: "pie",
            radius: ["55%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              // borderRadius: 10,
              borderColor: "#0d2d6d",
              borderWidth: 5,
            },
            label: {
              show: false,
              position: "center",
              normal: {
                show: true,
                formatter: `{name|${Number(this.zrdwdlrwwcz)}/${Number(this.zrdwdlrwmbz)}}\n{name|活动场次}`,
                position: "center",
                color: "#FFFFFF",
                rich: {
                  name: {
                    fontFamily: "Microsoft YaHei",
                    // fontStyle: " normal",
                    fontWeight: "700",
                    fontSize: "21",
                    lineHeight: "37",
                    // color: "#FFFFFF",

                    // textShadow: "0px 0px 7.90514px #246FFF",
                  },
                  active: {
                    fontFamily: "Microsoft YaHei",
                    fontStyle: " normal",
                    fontWeight: "700",
                    fontSize: "21",
                    lineHeight: "37px",
                    color: "#FFFFFF",
                    textShadow: "0px 0px 7.90514px #246FFF",
                  },
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            color: ["#1C85E7" ,"#00EBE9"],
            data: [
              { value: Number(this.zrdwdlrwmbz)- Number(this.zrdwdlrwwcz), name: "未完成" },
              { value: Number(this.zrdwdlrwwcz), name: "完成数" },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
  created() {},
  mounted() {
    this.drawEcharts();
  },
};
</script>

<style lang='scss' scoped>
img {
  width: 23px;
  height: 23px;
  margin-right: 12px;
}
.star {
  width: 211px;
  height: 56px;
  background: rgba(18, 68, 141, 0.5);
  border: 1px solid #007ee1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  .star-text {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 38px;
    text-align: right;

    color: #ffffff;
  }
}
.count {
  margin-top: 28px;
  .block {
    width: 22px;
    height: 14px;
    background: #00ebe9;
    border-radius: 1px;
    margin-right: 12px;
  }
  .count-text {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
  }
  .count-value {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    color: #00ebe9;
    margin-left: 28px;
  }
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content {
    // width: 438px;
    // height: 30px;
  .imgGroup {
    width: 17.58px;
    height: 20px;
  }
  .group-title {
    font-family: "PangMenZhengDao";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
    text-shadow: 0px 0px 10px #0085ea;
  }
}
.echarts-pie {
  width: 200px;
  height: 200px;
}
</style>