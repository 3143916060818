<template>
  <div class="content">
    <div class="flex btn-group-title">
      <img src="@/assets/home/Group 402.png" class="imgGroup" />
      <div class="group-title">{{ dataList.zbmc }}</div>
    </div>
    <div class="flex">
      <div :id="`echarts-bar-agr${this.id}`" class="echarts-bar-agr"></div>
      <div>
        <div class="star flex">
          <img src="@/assets/home/s.png" alt="" class="img" />
          <div class="star-text">目标值 {{ dataList.zrdwdlrwmbz }}</div>
        </div>
        <div class="count flex">
          <div class="block"></div>
          <div class="count-text">完成数</div>
          <div class="count-value">{{ dataList.zrdwdlrwwcz }}</div>
        </div>
      </div>
      <div class="agr">

      </div>
    </div>
  </div>
</template>
    <script>
export default {
  props: {
    dataList: {
      type: Object,
      default: function () {
        return {};
      },
    },
    id: {
      type: Number,
      default:null
    },
  },
  data() {
    return {
      zrdwdlrwwcz:[],
      zrdwdlrwmbz:[]
    };
  },
  methods: {
    drawEcharts() {
      this.zrdwdlrwwcz.push(this.dataList.zrdwdlrwwcz);
      this.zrdwdlrwmbz.push(this.dataList.zrdwdlrwmbz);
// console.log('Number(this.zrdwdlrwmbz[0])',Number(this.zrdwdlrwmbz[0]));

      var chartDoma = document.getElementById(`echarts-bar-agr${this.id}`);
      var myChart = this.$echarts.init(chartDoma);
      var option;

      option = {
        grid: {
          //与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算
          top: "10%",
          left: "29%",
          right: "0",
          bottom: "0",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: {
          type: "category",
          data: [""],
          axisLine: {
            show: false, //隐藏y轴
          },
          axisTick: {
            show: false, //刻度线
          },
          splitLine: {
            show: false, // 不显示网格线
          },
        },
        yAxis: {
          max:Math.ceil( Number(this.zrdwdlrwmbz[0])+Number(this.zrdwdlrwmbz[0])*0.3),
          type: "value",
          axisTick: {
            show: false, //刻度线
          },
          axisLine: {
            show: false, //隐藏y轴
          },
          axisLabel: {
            show: false, //隐藏刻度值
          },
          splitLine: {
            show: false, // 不显示网格线
          },
        },
        series: [
          {
            data: this.zrdwdlrwwcz,
            type: "pictorialBar",
            showBackground: true,
            barWidth: "100%",
            //图形形状
            symbol: "path://M150 50 L130 130 L170 130  Z",
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            label: {
              show: true,
              position: "inside",
              fontSize: 28,
            },
            markLine: {
              symbol: "none",

              data: [
                {
                  silent: false, //鼠标悬停事件  true没有，false有
                  lineStyle: {
                    //警戒线的样式  ，虚实  颜色
                    type: "dashed",
                    color: "red",
                  },
                  label: {
                    position: "start",
                    formatter: `${Number(this.zrdwdlrwmbz[0])}`,
                    fontSize: "16",
                    fontStyle: "normal",
                  },
                  yAxis: Number(this.zrdwdlrwmbz[0]), // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                },
              ],
            },
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    // 四个数字分别对应 数组中颜色的开始位置，分别为 右，下，左，上。例如（1,0,0,0 ）代表从右边开始渐
                    // 变。offset取值为0~1，0代表开始时的颜色，1代表结束时的颜色，柱子表现为这两种颜色的渐变。
                    offset: 0,
                    color: "rgba(0, 235, 233, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(0, 133, 234, 1)",
                  },
                ]),
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
  created() {},
  mounted() {
    this.drawEcharts();
  },
};
</script>
    
    <style lang='scss' scoped>
    // .agr{
    //   width: 102px;
    //   height: 228px;
    //   position: absolute;
    //   background-repeat: no-repeat;
    //   background-size: 100% 100% ;
    //   background-image: url('../../../assets/home/Polygon 1.png');
    // }
img {
  width: 23px;
  height: 23px;
  margin-right: 12px;
}
.star {
  width: 211px;
  height: 56px;
  background: rgba(18, 68, 141, 0.5);
  border: 1px solid #007ee1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  .star-text {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 38px;
    text-align: right;

    color: #ffffff;
  }
}
.count {
  margin-top: 28px;
  .block {
    width: 22px;
    height: 14px;
    background: #00ebe9;
    border-radius: 1px;
    margin-right: 12px;
  }
  .count-text {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
  }
  .count-value {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    color: #00ebe9;
    margin-left: 28px;
  }
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content {
  // width: 438px;
  // height: 30px;
  position: relative;
  .imgGroup {
    width: 17.58px;
    height: 20px;
  }
  .group-title {
    font-family: "PangMenZhengDao";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
    text-shadow: 0px 0px 10px #0085ea;
  }
}
.echarts-bar-agr {
  margin-top: 20px;
  width: 160px;
  height: 200px;
  margin-left: 54px;
  margin-right: 60px;
}
</style>