<template>
  <div class="main-content-center-two">
    <div class="center-info">
      <div class="info-title">{{ name }}</div>
    </div>
    <div class="info" v-if="totalCount < 3">
      <div v-for="(item, i) in dataList" :key="i" class="width">
        {{ item.typeCount }}
        <echart-bar-agr
          class="width-info"
          v-if="item.typeCount && item.typeCount == 5"
          :dataList="item"
          :id="i"
        ></echart-bar-agr>
        <echartbar
          class="width-info"
          v-if="item.typeCount && item.typeCount == 6"
          :dataList="item"
          :id="i"
        ></echartbar>
        <echart-pie
          class="width-info"
          v-if="item.typeCount && item.typeCount == 7"
          :dataList="item"
          :id="i"
        ></echart-pie>
        <echart-line
          class="line"
          v-if="item.typeCount && item.typeCount == 8"
          :dataList="item"
          :id="i"
        ></echart-line>

        <div v-if="!item.typeCount" class="width-info">
          <div class="flex btn-group-title">
            <img src="@/assets/home/Group 402.png" class="imgGroup" />
            <div class="group-title">{{ item.zbmc }}</div>
          </div>
          <div class="switch">
            <div class="mySwitchWwc" v-if="item.zrdwdxrwwcztid == '1'"></div>
            <div class="mySwitchWc" v-if="item.zrdwdxrwwcztid == '0'"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="info-out">
      <div v-for="(item, i) in dataList" :key="i" class="width">
        <echart-bar-agr
          class="width-info"
          v-if="item.typeCount && item.typeCount == 5"
          :dataList="item"
          :id="i"
        ></echart-bar-agr>
        <echart-pie
          class="width-info"
          v-if="item.typeCount && item.typeCount == 6"
          :id="i"
          :dataList="item"
        ></echart-pie>
        <echartbar
          class="width-info"
          v-if="item.typeCount && item.typeCount == 7"
          :id="i"
          :dataList="item"
        ></echartbar>
        <echart-line
          class="line"
          :id="i"
          v-if="item.typeCount && item.typeCount == 8"
          :dataList="item"
        ></echart-line>
        <div v-if="!item.typeCount" class="width-info">
          <div class="flex btn-group-title">
            <img src="@/assets/home/Group 402.png" class="imgGroup" />
            <div class="group-title">{{ item.zbmc }}</div>
          </div>
          <div class="switch">
            <div class="mySwitchWwc" v-if="item.zrdwdxrwwcztid == '1'"></div>
            <div class="mySwitchWc" v-if="item.zrdwdxrwwcztid == '0'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echartPie from "@/views/main-view/workbench/echart-pie.vue";
import echartbar from "@/views/main-view/workbench/echart-bar.vue";
import echartLine from "@/views/main-view/workbench/echart-line.vue";
import EchartBarAgr from "@/views/main-view/workbench/echart-bar-agr.vue";
export default {
  components: {
    echartPie,
    echartbar,
    EchartBarAgr,
    echartLine,
    // echartPie
  },
  props: {
    totalCount: {
      type: Number,
      default: 1,
    },
    name: {
      type: String,
      default: "",
    },
    dataList: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang='scss' scoped>
.line {
  width: 977px !important;
  height: 444px !important;
}
// .width{
// width: 100%;

// }
.width-info {
  width: 482px;
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.main-content-center-two {
  display: flex;
  flex-direction: column;
  width: 1036px;
  height: 780px;
  margin-left: 103px;
  margin-right: 81px;
  margin-top: 66px;
  .center-info {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .info-title {
    width: 825px;
    height: 78px;
    font-family: "PangMenZhengDao";
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;

    text-shadow: 0px 0px 10px #0085ea;
  }
  .info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .info-out {
    width: 1036px;
    height: 704px;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .info-out::-webkit-scrollbar {
    display: none;
  }
  .switch {
    margin-top: 50px;
    margin-bottom: 50px;
    // margin-left: 130px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 97px;
    .mySwitchWwc {
      width: 170px;
      height: 60px;
      background-image: url("../../../assets/home/wancheng.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .mySwitchWc {
      width: 170px;
      height: 60px;
      background-image: url("../../../assets/home/weiwancheng.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .imgGroup {
    width: 17.58px;
    height: 20px;
  }
  .group-title {
    font-family: "PangMenZhengDao";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
    text-shadow: 0px 0px 10px #0085ea;
  }
}
</style>