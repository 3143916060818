<template>
  <div class="content">
    <div class="flex btn-group-title">
      <img src="@/assets/home/Group 402.png" class="imgGroup" />
      <div :class="`group-title`">{{ dataList.zbmc }}</div>
    </div>
    <div>
      <div class="star flex">
        <img src="@/assets/home/s.png" alt="" class="img" />
        <div class="star-text">目标值 {{ dataList.zrdwdlrwwcz }}</div>
      </div>
    </div>
    <div class="flex">
      <div :id="`echarts-line${this.id}`" class="echarts-line"></div>
    </div>
  </div>
</template>
    <script>
export default {
  props: {
    dataList: {
      type: Object,
      default: function () {
        return {};
      },
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      zrdwdlrwwcz:[],
      zrdwdlrwmbz:[]
    };
  },
  methods: {
    drawEcharts() {
      this.zrdwdlrwwcz.push(this.dataList.zrdwdlrwwcz);
      this.zrdwdlrwmbz.push(this.dataList.zrdwdlrwmbz);
      // console.log('Number(this.dataChartswcz[0])',Number(this.zrdwdlrwmbz[0])+Number(this.zrdwdlrwmbz[0])*0.3);
      
      var chartDoma = document.getElementById(`echarts-line${this.id}`);
      var myChart = this.$echarts.init(chartDoma);
      var option;
      option = {
        grid: {
          //与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算
          top: "20%",
          left: "2%",
          right: "0",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
          max:Math.ceil( Number(this.zrdwdlrwmbz[0])+Number(this.zrdwdlrwmbz[0])*0.3),
        },
        series: [
          {
            data: this.zrdwdlrwwcz,
            type: "line",
            symbol: "circle",
            smooth: true,
            label: {
              show: true,
              fontSize: 40,
              position: "inside",
              color: " #FFFFFF",
              // fontSize:26
            },
            markLine: {
              symbol: "none",

              /*symbol:"none",               //去掉警戒线最后面的箭头
                             label:{
                                 position:"end"   ,       //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                              
                              
                             },*/
              data: [
                {
                  silent: false, //鼠标悬停事件  true没有，false有
                  lineStyle: {
                    //警戒线的样式  ，虚实  颜色
                    type: "dashed",
                    color: "red",
                  },
                  label: {
                    position: "start",
                    formatter: `${Number(this.zrdwdlrwmbz[0])}`,
                    fontSize: "16",
                    fontStyle: "normal",
                  },
                  yAxis:Number(this.zrdwdlrwmbz[0]), // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                },
              ],
            },
            itemStyle: {
              normal: {
                color: "#F58119", //拐点颜色
                borderColor: "#F58119", //拐点边框颜色
                borderWidth: 3, //拐点边框大小
              },
              emphasis: {
                //突出效果配置(鼠标置于拐点上时)
                borderColor: "##F58119", //  拐点边框颜色
                borderWidth: 2, //  拐点边框宽度
                shadowColor: "##F58119", //  阴影颜色
                shadowBlur: 3, //  阴影渐变范围控制
                color: "#F58119", //hover拐点颜色定义
              },
            },
            symbolSize: 90,
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
  created() {},
  mounted() {
    this.$nextTick(()=>{
      // console.log(document.getElementById(`echarts-line${this.id}`))
      this.drawEcharts();

    })
  },
};
</script>
    
    <style lang='scss' scoped>
img {
  width: 23px;
  height: 23px;
  margin-right: 12px;
}
.star {
  position: absolute;
  right: 55px;
  width: 211px;
  height: 56px;
  background: rgba(18, 68, 141, 0.5);
  border: 1px solid #007ee1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  .star-text {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 38px;
    text-align: right;

    color: #ffffff;
  }
}
.count {
  margin-top: 28px;
  .block {
    width: 22px;
    height: 14px;
    background: #00ebe9;
    border-radius: 1px;
    margin-right: 12px;
  }
  .count-text {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
  }
  .count-value {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    color: #00ebe9;
    margin-left: 28px;
  }
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content {
  width: 438px;
  height: 30px;
  position: relative;
  .imgGroup {
    width: 17.58px;
    height: 20px;
  }
  .group-title {
    font-family: "PangMenZhengDao";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
    text-shadow: 0px 0px 10px #0085ea;
  }
}
.echarts-line{
  margin-top: 50px;
  width: 977px;
  height: 350px;
  margin-left: 54px;
  margin-right: 60px;
}
</style>