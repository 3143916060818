<template>
  <screen-adapter>
    <div class="main-view">
      <div class="main-content">
        <div class="select-year" v-show="false">
          <el-form
            ref="form"
            :model="formYear"
            label-width="80px"
            class="form-year"
          >
            <div class="flex">
              <el-form-item label="">
                <el-select
                  v-model="formYear.valueOption"
                  placeholder="请选择"
                  @change="handleNativeYear"
                  size="mini"
                >
                  <el-option
                    v-for="item in yearsList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="main-content-left">
          <div class="left-top-img">
            <div class="text">党委统一战线工作</div>
            <div class="text">领导小组重大任务</div>
          </div>
          <div class="flex marTop">
            <div class="btn color-btn" @click="toPage()">市本级</div>
            <div class="btn">乡镇/街道</div>
          </div>
          <div class="table">
            <div class="table-title">
              <div class="id">序号</div>
              <div class="name">重大任务名称</div>
              <div class="flex title-count">
                <div class="count">执行率</div>
                <div class="icon">
                  <i
                    :class="
                      orderType === 1
                        ? 'el-icon-caret-top color'
                        : 'el-icon-caret-top'
                    "
                    @click="upTop(1)"
                  ></i>
                  <i
                    :class="
                      orderType === 0
                        ? 'el-icon-caret-bottom color'
                        : 'el-icon-caret-bottom'
                    "
                    @click="upTop(0)"
                  ></i>
                </div>
              </div>
            </div>

            <div style="height: 586px; overflow: hidden" ref="contentInfoTable">
              <div
                v-for="(item, i) in tableList"
                :class="
                  chooseIndex === i
                    ? 'flex table-item choose-item '
                    : 'flex table-item'
                "
                :key="i"
                @click="rightListEvent(item.rwid, item.rwmc, i)"
              >
                <div v-if="i + 1 < 4" :class="i + 1 < 4 ? 'id bg-id' : 'id'">
                  {{ i + 1 }}
                </div>
                <div v-else :class="i + 1 > 5 ? 'id' : 'id'">{{ i + 1 }}</div>

                <div class="name">{{ item.rwmc }}</div>
                <div class="count">
                  {{ item.complateNum }}/{{ item.totalNum }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="firstInto">
          <!-- <index-bar
           :centerCount="centerCount"
           :centerBar="centerBar"
          ></index-bar> -->
          <div>
            <div class="main-content-center">
              <div>
                <div class="center-top-box">
                  <div
                    v-for="(item, i) in numberList"
                    :key="i"
                    class="center-top"
                  >
                    <div class="center-count">{{ item.count }}</div>
                    <img :src="item.img" class="img" />
                    <div class="center-data">{{ item.data }}</div>
                  </div>
                </div>
                <div class="chart">
                  <div id="chart-panel" v-if="isBarShow"></div>
                  <div class="bg-box" v-if="isBarShow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <center-info
          v-else
          :name="name"
          :totalCount="leftTableList.length"
          :dataList="leftTableList"
        ></center-info>
        <!-- <div class="main-content-center-two" v-if="false">
          <div class="center-info">
            <div class="info-title">加强党外干部战略性发现培养选拔</div>
          </div>
          <div class="center-two-box">
            <div
              v-for="(item, i) in 1"
              :key="i"
              :class="i % 2 === 0 ? 'btn-group-two' : ''"
            >
              <div class="flex btn-group-title">
                <img src="@/assets/home/Group 402.png" class="imgGroup" />
                <div class="group-title">
                  开展“商户跟党走”各类主体教育活动场次
                </div>
              </div>
              <div class="switch">
                <div class="mySwitchWwc" v-if="false"></div>
                <div class="mySwitchWc" v-if="true"></div>
              </div>
              <echart-bar-agr></echart-bar-agr>
              <echart-line></echart-line>
            </div>
          </div>
        </div>

        <div class="main-content-center-two" v-if="false">
          <div class="center-info">
            <div class="info-title">加强党外干部战略性发现培养选拔</div>
          </div>
          <div class="center-two-box">
            <div
              v-for="(item, i) in 6"
              :key="i"
              :class="i % 2 === 0 ? 'btn-group-two' : ''"
            >
              <div class="flex btn-group-title">
                <img src="@/assets/home/Group 402.png" class="imgGroup" />
                <div class="group-title">
                  开展“商户跟党走”各类主体教育活动场次
                </div>
              </div>
              <div class="switch">
                <div class="mySwitchWwc" v-if="false"></div>
                <div class="mySwitchWc" v-if="true"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-content-center-three" v-if="false">
          <div class="center-info">
            <div class="info-title">
              打造一支与现代化相适宜的民营经济代表人士队伍，推动新时代民营经济新飞跃
            </div>
          </div>
          <div class="info-item">
            <echart-pie class="mar-right"></echart-pie>
            <echart-bar></echart-bar>
          </div>
        </div>
        <div class="main-content-center-four" v-if="false">
          <div class="center-info">
            <div class="info-title">
              打造一支与现代化相适宜的民营经济代表人士队伍，推动新时代民营经济新飞跃
            </div>
          </div>
          <div class="info-item"> -->
        <!-- <div class="info-four"> -->
        <!-- <echart-pie class="mar-right"></echart-pie> -->
        <!-- <echart-bar-agr></echart-bar-agr> -->
        <!-- <echart-bar-agr></echart-bar-agr> -->
        <!-- <echart-line></echart-line> -->
        <!-- </div> -->
        <!-- </div>  -->
        <!-- 弹窗 -->
        <div id="dialog">
          <el-dialog
            v-if="firstInto"
            :visible.sync="dialogTableVisible"
            :show-close="false"
            :modal="false"
            :modal-append-to-body="false"
          >
            <div class="main-content-center-table">
              <el-form ref="form" :model="form" label-width="80px" class="form">
                <div class="flex">
                  <el-form-item label="年度:">
                    <el-select
                      v-model="form.valueOption"
                      placeholder="请选择"
                      @change="handleNative"
                    >
                      <el-option
                        v-for="item in yearsList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="活动名称:">
                    <el-select
                      v-model="form.valueOptionOne"
                      placeholder="请选择"
                      @change="handleNative"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-button @click="restartData">重置</el-button>
                </div>
                <el-table
                  :fit="false"
                  :header-cell-style="{ background: '#102954' }"
                  :data="tableDataList"
                  style="width: 920px; height: 588px"
                  :row-class-name="tableRowClassName"
                >
                  <el-table-column
                    :prop="item.prop"
                    :label="item.name"
                    width="176px"
                    v-for="(item, i) in tableData"
                    :key="i"
                  ></el-table-column>
                </el-table>
                <div class="page">
                  <el-pagination
                    background
                    prev-text="上一页"
                    next-text="下一页"
                    layout="prev, pager, next"
                    :total="total"
                    :current-page="tablePage.pageNum"
                    :page-size="tablePage.pageSize"
                    :page-sizes="pageSizes"
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                  >
                  </el-pagination>
                </div>
              </el-form>
              <!-- 
  <el-table :data="gridData">
    <el-table-column property="date" label="日期" width="150"></el-table-column>
    <el-table-column property="name" label="姓名" width="200"></el-table-column>
    <el-table-column property="address" label="地址"></el-table-column>
  </el-table> -->
            </div>
            <div class="close" @click="dialogTableVisible = false"></div>
          </el-dialog>
        </div>

        <div class="main-content-right">
          <div class="right-title">牵头单位比拼晾晒台</div>
          <div class="btn-group flex">
            <div
              :class="current === 0 ? ' count currentCount' : 'count'"
              @click="countEvent(0)"
            >
              指标数切换排行榜
            </div>
            <div
              :class="current === 1 ? 'currentCount cursor' : ' cursor'"
              @click="countEvent(1)"
            >
              执行完成率排行榜
            </div>
          </div>
          <div class="table">
            <div class="table-title">
              <div class="name">单位名称</div>
              <div class="icon-unit">
                <i
                  :class="
                    orderTypeRight === 1
                      ? 'el-icon-caret-top color'
                      : 'el-icon-caret-top '
                  "
                  @click="caretTop(1)"
                ></i>
                <i
                  :class="
                    orderTypeRight === 0
                      ? 'el-icon-caret-bottom color'
                      : 'el-icon-caret-bottom'
                  "
                  @click="caretTop(0)"
                ></i>
              </div>
              <div class="id">指标数</div>
              <div class="flex title-count">
                <div class="count">执行进度</div>
              </div>
            </div>
            <div style="height: 586px; overflow: hidden" ref="contentInfo">
              <div
                v-for="(item, i) in tableRightList"
                :class="
                  currentIndex === i
                    ? 'flex table-item choose-item'
                    : 'flex table-item'
                "
                :key="i"
                @click="dialogEvent(item.qtdwId, i)"
              >
                <div class="name">
                  {{ item.zrdwmc ? item.zrdwmc : item.qtdwName }}
                </div>
                <div class="id">{{ item.totalNum }}</div>
                <div class="count">
                  {{ item.complateNum }}/{{ item.totalNum }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-bottom" v-if="false">
        <div
          v-for="(item, i) in btnGroup"
          :key="i"
          class="btn-group-bottom"
          @mouseenter="MouseEnterEvent(item.name)"
        >
          {{ item.name }}
        </div>
      </div>
      <div v-if="moreShow" class="more-bored" @mouseleave="MouseLeaveEvent">
        <div class="btn-group-more">
          <div
            v-for="(item, i) in btnGroupCity"
            :key="i"
            class="btn-group-name"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </screen-adapter>
</template>
<script>
// import echartPie from '@/views/main-view/workbench/echart-pie.vue'
// import echartPie from "./workbench/echart-pie.vue";
// import echartBar from "./workbench/echart-bar.vue";
// import echartLine from "./workbench/echart-line.vue";
import centerInfo from "./workbench/center-info.vue";

import screenAdapter from "./workbench/screen-adapter.vue";
// import EchartBarAgr from "./workbench/echart-bar-agr.vue";
import {
  getIllegalRecord,
  getQtdwaindex,
  getActionindex,
  getList,
  getIndexdetail,
  getCount,
  getcountbydeptList,
} from "@/api/city/index.js";
// import IndexBar from "./workbench/index-bar.vue";
export default {
  components: {
    screenAdapter,
    // echartPie,
    // echartBar,
    // EchartBarAgr,
    // echartLine,
    // IndexBar,
    centerInfo,
    // echartPie
  },
  data() {
    return {
      dialogTableVisible: false,
      total: 0,
      firstInto: true,
      currentIndex: null,
      // timer:null,
      btnGroup: [
        {
          name: "双塔街道",
        },
        {
          name: "虎山街道",
        },
        {
          name: "清湖街道",
        },
        {
          name: "上余镇",
        },
        {
          name: "更多",
        },
      ],
      btnGroupCity: [
        {
          name: "四都镇",
        },
        {
          name: "大陈乡",
        },
        {
          name: "碗窑乡",
        },
        {
          name: "贺村镇",
        },
        {
          name: "坛石镇",
        },
        {
          name: "大桥镇",
        },
        {
          name: "新塘边镇",
        },
        {
          name: "峡口镇",
        },
        {
          name: "廿八都镇",
        },
        {
          name: "保安乡",
        },
        {
          name: "长台镇",
        },
        {
          name: "石门镇",
        },
        {
          name: "张村乡",
        },
        {
          name: "塘源口乡",
        },
      ],
      numberList: [
        {
          count: 0,
          img: require("@/assets/home/green.png"),
          data: "任务总数",
        },
        {
          count: 0,
          img: require("@/assets/home/blue.png"),
          data: "指标数",
        },
        {
          count: 0,
          img: require("@/assets/home/yellow.png"),
          data: "任务完成数",
        },
        {
          count: 0,
          img: require("@/assets/home/blue-one.png"),
          data: "指标完成数",
        },
      ],
      indexName: [],
      actionTotalCount: [],
      actionComplateCount: [],
      indexTotalCount: [],
      indexComplateCount: [],
      totalData: [],
      totalData1: [],
      tableDataList: [
        {
          qtdwmc: "2016-05-03",
          rwmc: "王小虎",
          zbmc: "上海",
          zrdwmc: "普陀区",
          zrdwdlrwmbz: "上海市普陀区金沙江路 1516 弄",
          zrdwdlrwwcz: 200333,
          zbztMc: "公司",
        },
        {
          qtdwmc: "2016-05-03",
          rwmc: "王小虎",
          zbmc: "上海",
          zrdwmc: "普陀区",
          zrdwdlrwmbz: "上海市普陀区金沙江路 1516 弄",
          zrdwdlrwwcz: 200333,
          zbztMc: "公司",
        },
        {
          qtdwmc: "2016-05-03",
          rwmc: "王小虎",
          zbmc: "上海",
          zrdwmc: "普陀区",
          zrdwdlrwmbz: "上海市普陀区金沙江路 1516 弄",
          zrdwdlrwwcz: 200333,
          zbztMc: "公司",
        },
        {
          qtdwmc: "2016-05-03",
          rwmc: "王小虎",
          zbmc: "上海",
          zrdwmc: "普陀区",
          zrdwdlrwmbz: "上海市普陀区金沙江路 1516 弄",
          zrdwdlrwwcz: 200333,
          zbztMc: "公司",
        },
        {
          qtdwmc: "2016-05-03",
          rwmc: "王小虎",
          zbmc: "上海",
          zrdwmc: "普陀区",
          zrdwdlrwmbz: "上海市普陀区金沙江路 1516 弄",
          zrdwdlrwwcz: 200333,
          zbztMc: "公司",
        },
        {
          qtdwmc: "2016-05-03",
          rwmc: "王小虎",
          zbmc: "上海",
          zrdwmc: "普陀区",
          zrdwdlrwmbz: "上海市普陀区金沙江路 1516 弄",
          zrdwdlrwwcz: 200333,
          zbztMc: "公司",
        },
      ],
      tableData: [
        {
          prop: "qtdwmc",
          name: "牵头单位",
        },
        {
          prop: "rwmc",
          name: "重大任务",
        },
        {
          prop: "zbmc",
          name: "指标名称",
        },
        {
          prop: "zrdwmc",
          name: "责任单位",
        },
        {
          prop: "zrdwdlrwmbz",
          name: "目标数",
        },
        {
          prop: "zrdwdlrwwcz",
          name: "完成值",
        },
        {
          prop: "zbztMc",
          name: "状态",
        },
      ],
      tablePage: {
        pageNum: 1, // 第几页

        pageSize: 10, // 每页多少条

        total: 0, // 总记录数
      },
      // 默认显示第一条
      pageSizes: [10, 20, 30],
      currentPage: 1,
      qtdwid: 0,
      options: [
        {
          value: "2",
          label: "执行中",
        },
        {
          value: "3",
          label: "已完成",
        },
      ],
      form: {
        valueOption: "2023",
        valueOptionOne: "",
      },
      formYear: {
        valueOption: "2023",
      },
      orderType: 0,
      tableList: [],
      // tableList: [
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领11111111111111111111111111111",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      //   {
      //     name: "协助统一战线领",
      //     count: "2/5",
      //   },
      // ],
      tableRightList: [],
      // btnGroup: [{}],
      value: true,
      current: 0,
      orderByRight: 0,
      orderTypeRight: 0,
      yearsList: [],
      name: "",
      leftTableList: [],
      centerCount: [],
      centerBar: [],
      isBarShow: true,
      moreShow: false,
      chooseIndex: null,
    };
  },
  methods: {
    MouseEnterEvent(e) {
      console.log("jinru", e);
      e === "更多" ? (this.moreShow = true) : (this.moreShow = false);
      // this.moreShow = true
    },
    MouseLeaveEvent(e) {
      console.log("chuq");
      console.log("e", e);

      this.moreShow = false;
    },
    toPage() {
      this.chooseIndex = null;
      this.currentIndex = null;
      this.firstInto = true;
      // this.isBarShow= true
      this.getData();
      this.getRightTable();
    },
    getData() {
      // this.firstInto = true;
      const params = {
        year: this.formYear.valueOption ? this.formYear.valueOption : "2023",
      };
      getCount(params).then((res) => {
        console.log("res", res);
        // this.centerBar = res.data.data
        this.numberList[0].count = res.data.data.actionTotalCount;
        this.numberList[1].count = res.data.data.indexTotalCount;
        this.numberList[2].count = res.data.data.actionComplateCount;
        this.numberList[3].count = res.data.data.indexComplateCount;
      });
      getcountbydeptList(params).then((res) => {
        this.indexName = [];
        this.actionTotalCount = [];
        this.actionComplateCount = [];
        this.indexTotalCount = [];
        this.indexComplateCount = [];
        this.centerBar = res.data.data;
        if (res.data.data.length == 0) {
          this.isBarShow = false;
        } else {
          res.data.data.map((item) => {
            this.indexName.push(item.qtdwmc);
            this.actionTotalCount.push(item.actionTotalCount);
            this.actionComplateCount.push(item.actionComplateCount);
            this.indexTotalCount.push(item.indexTotalCount);
            this.indexComplateCount.push(item.indexComplateCount);
            // 顶部的值 是 data + data1的值
            this.totalData.push(
              Number(item.actionComplateCount) + Number(item.actionTotalCount)
            );
            this.totalData1.push(
              Number(item.indexTotalCount) + Number(item.indexComplateCount)
            );
            // console.log('this.totalData',this.totalData,this.totalData1);
          });
          this.isBarShow = true;
          // console.log('res.data.data',res.data.data);
        }
        this.$nextTick(() => {
          this.drawChart();
        });
      });
    },

    drawChart() {
      var serveTBar = this.$echarts.init(
        document.getElementById("chart-panel")
      );
      // console.log("this", this.indexTotalCount);
      const data = this.actionComplateCount;
      const data1 = this.actionTotalCount;
      const data2 = this.indexTotalCount;
      const data3 = this.indexComplateCount;
      // console.log('datadatadatadata',data);

      //   const sideData = data.map((item) => item + 9);
      //   const sideData2 = data2.map((item) => item + 9);
      var colorArr = ["#173EA3", "#499FF3", "#2570BB"];
      var colorArr1 = ["#13AAAC", "#1DDDE1", "#1DE6E6"];
      var colorArr3 = ["#1d5477", "#3d9c66", "#3d9c66"];
      var colorArr4 = ["#5a673e", "#737a2e", "#acbd46"];

      var color = {
        type: "linear",
        x: 0,
        x2: 1,
        y: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: colorArr[0],
          },
          {
            offset: 0.5,
            color: colorArr[0],
          },
          {
            offset: 0.5,
            color: colorArr[1],
          },
          {
            offset: 1,
            color: colorArr[1],
          },
        ],
      };
      var color1 = {
        type: "linear",
        x: 0,
        x2: 1,
        y: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: colorArr1[0],
          },
          {
            offset: 0.5,
            color: colorArr1[0],
          },
          {
            offset: 0.5,
            color: colorArr1[1],
          },
          {
            offset: 1,
            color: colorArr1[1],
          },
        ],
      };
      var color3 = {
        type: "linear",
        x: 0,
        x2: 1,
        y: 1,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: colorArr3[0],
          },
          {
            offset: 0.5,
            color: colorArr3[0],
          },
          {
            offset: 0.5,
            color: colorArr3[1],
          },
          {
            offset: 1,
            color: colorArr3[1],
          },
        ],
      };
      var color4 = {
        type: "linear",
        x: 0,
        x2: 1,
        y: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: colorArr4[0],
          },
          {
            offset: 0.5,
            color: colorArr4[0],
          },
          {
            offset: 0.5,
            color: colorArr4[1],
          },
          {
            offset: 1,
            color: colorArr4[1],
          },
        ],
      };
      var barWidth = 30;
      var option = {
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: function (params) {
            var str = params[0].name + ":";
            params.filter(function (item) {
              if (item.componentSubType == "bar") {
                str += "<br/>" + item.seriesName + "：" + item.value;
              }
            });
            return str;
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "rgba(255, 255,255, 0.1)",
              width: 100,
              type: "solid",
            },
            z: 0,
          },
        },
        grid: {
          x: "7%",
          x2: "0%",
          y: "15%",
          y2: "15%",
        },
        legend: {
          show: false,
          data: ["", ""],
          textStyle: {
            color: "#fff",
            fontSize: "20",
          },
        },
        xAxis: {
          data: this.indexName,
          //坐标轴
          axisLine: {
            show: false,
            lineStyle: {
              width: 1,
              color: "#214776",
            },
            textStyle: {
              color: "#fff",
              fontSize: "10",
            },
          },
          type: "category",
          axisLabel: {
            show: true, //隐藏刻度值

            rotate: -40,
            textStyle: {
              color: "#C5DFFB",
              fontWeight: 500,
              fontSize: "14",
            },
          },
          axisTick: {
            textStyle: {
              color: "#fff",
              fontSize: "16",
            },
            show: false,
          },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          //坐标轴
          axisLine: {
            show: false,
            lineStyle: {
              width: 1,
              color: "#214776",
            },
            textStyle: {
              color: "#fff",
              fontSize: "10",
            },
          },
          axisTick: {
            show: false,
          },
          //坐标值标注
          axisLabel: {
            show: false,
            textStyle: {
              color: "#C5DFFB",
            },
          },
          //分格线
          splitLine: {
            lineStyle: {
              color: "#13365f",
            },
          },
        },
        series: [
          {
            z: 1,
            name: "任务完成数",
            type: "bar",
            stack: "Ad",
            barWidth: barWidth,
            barGap: "0%",
            data: data,
            itemStyle: {
              normal: {
                color: color,
              },
            },
          },
          {
            z: 1,
            name: "任务总数",
            type: "bar",
            stack: "Ad",
            barWidth: barWidth,
            barGap: "0%",
            data: data1,
            itemStyle: {
              normal: {
                color: color1,
              },
            },
          },
          {
            z: 2,
            name: "3",
            type: "pictorialBar",
            data: data2,
            stack: "Ad",
            symbol: "diamond",
            symbolOffset: ["-75%", "50%"],
            symbolSize: [barWidth, 10],
            itemStyle: {
              normal: {
                color: color,
              },
            },
            tooltip: {
              show: false,
            },
          },
          {
            z: 3,
            name: "4",
            type: "pictorialBar",
            symbolPosition: "end",
            data: data,
            stack: "Ad",
            symbol: "diamond",
            symbolOffset: ["-75%", "-50%"],
            symbolSize: [barWidth - 4, (10 * (barWidth - 4)) / barWidth],
            itemStyle: {
              normal: {
                borderWidth: 2,
                color: colorArr[2],
              },
            },
            tooltip: {
              show: false,
            },
          },
          {
            z: 4,
            name: "4",
            type: "pictorialBar",
            symbolPosition: "end",
            data: this.totalData,
            stack: "Ad",
            symbol: "diamond",
            symbolOffset: ["-90%", "-60%"],
            symbolSize: [barWidth - 4, (10 * (barWidth - 4)) / barWidth],
            itemStyle: {
              normal: {
                borderWidth: 2,
                color: colorArr1[2],
              },
            },
            tooltip: {
              show: false,
            },
          },

          //   第二列
          {
            z: 1,
            name: "指标数",
            type: "bar",
            stack: "tq",
            barWidth: barWidth,
            barGap: "50%",
            data: data2,
            itemStyle: {
              normal: {
                color: color3,
              },
            },
          },
          {
            z: 1,
            name: "指标完成数",
            type: "bar",
            stack: "tq",
            barWidth: barWidth,
            barGap: "50%",
            data: data3,
            itemStyle: {
              normal: {
                color: color4,
              },
            },
          },
          {
            z: 2,
            name: "7",
            stack: "tq",
            type: "pictorialBar",
            data: data2,
            symbol: "diamond",
            symbolOffset: ["75%", "50%"],
            symbolSize: [barWidth, 10],
            itemStyle: {
              normal: {
                color: colorArr3[1],
              },
            },
            tooltip: {
              show: false,
            },
          },

          {
            z: 3,
            name: "8",
            stack: "tq",
            type: "pictorialBar",
            symbolPosition: "end",
            data: data2,
            symbol: "diamond",
            symbolOffset: ["75%", "-50%"],
            symbolSize: [barWidth - 4, (10 * (barWidth - 4)) / barWidth],
            itemStyle: {
              normal: {
                borderWidth: 2,
                color: colorArr3[2],
              },
            },
            tooltip: {
              show: false,
            },
          },
          {
            z: 4,
            name: "4",
            type: "pictorialBar",
            symbolPosition: "end",
            data: this.totalData1,
            stack: "tq",
            symbol: "diamond",
            symbolOffset: ["85%", "-60%"],
            symbolSize: [barWidth - 4, (10 * (barWidth - 4)) / barWidth],
            itemStyle: {
              normal: {
                borderWidth: 2,
                color: colorArr4[2],
              },
            },
            tooltip: {
              show: false,
            },
          },
        ],
      };
      serveTBar.setOption(option);
    },

    handlePageChange(currentPage) {
      this.tablePage.pageNum = currentPage;
      // console.log("currentPage", currentPage);
      this.getRightDialog();
      // 在此刷新数据
    },

    handleSizeChange(pageSize) {
      this.tablePage.pageSize = pageSize;
      // console.log("pageSiez", pageSize);

      // 在此刷新数据
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    rightListEvent(id, name, i) {
      this.chooseIndex = i;
      this.firstInto = false;
      this.name = name;
      this.rwid = id;
      const prams = {
        rwid: this.rwid,
        orderBy: this.orderByRight,
        orderType: this.orderTypeRight,
      };
      getList(prams).then((res) => {
        this.tableRightList = res.data.data;
      });
      const pramsOne = {
        rwid: this.rwid,
      };
      getIndexdetail(pramsOne).then((res) => {
        // console.log("res", res);
        res.data.data.map((item) => {
          if (item.zbrwlxBh == 0) {
            item["typeCount"] = Math.floor(Math.random() * 4 + 5);
            // console.log("item.typeCount", item.typeCount);
          }
        });

        this.leftTableList = res.data.data;
        // console.log("this.leftTableList", this.leftTableList);
      });
    },
    handleNative() {
      // console.log('    this.form.valueOption',    this.form.valueOption ,
      //       this.form.valueOptionOne );
      this.getRightDialog();
    },
    handleNativeYear() {
      this.getRightTable();
      this.getRecord();
      this.getData();
    },
    restartData() {
      this.form.valueOption = "";
      this.form.valueOptionOne = "";
      this.getRightDialog();
    },
    //设置日期列表
    initYears() {
      var myDate = new Date();
      var year = myDate.getFullYear(); //获取当前年
      this.initSelectYear(year);
    },
    initSelectYear(year) {
      this.yearsList = [];
      for (let i = 0; i < 30; i++) {
        this.yearsList.push({ value: year - i, label: year - i + "年" });
      }
    },

    caretTop(i) {
      this.orderTypeRight = i;
      this.getRightTable();
    },
    countEvent(i) {
      this.currentIndex = null;
      this.current = i;

      //orderBy 0指标数 1执行率
      this.orderByRight = i;
      this.getRightTable();
    },
    upTop(i) {
      this.orderType = i;
      this.getRecord();
    },
    getRightDialog() {
      // console.log("进入了接口", this.valueOption);

      const prams = {
        size: this.tablePage.pageSize,
        year: this.form.valueOption ? this.form.valueOption : "2023",
        current: this.tablePage.pageNum,
        qtdwid: this.qtdwid,
        status: this.form.valueOptionOne ? this.form.valueOptionOne : "",
      };
      getActionindex(prams).then((res) => {
        // console.log("res", res);
        this.tableDataList = res.data.data.records;
        this.total = res.data.data.total;
        // console.log("this.total", this.total);
      });
    },
    getRightTable() {
      const params = {
        year: this.formYear.valueOption ? this.formYear.valueOption : "2023",
        orderBy: this.orderByRight,
        orderType: this.orderTypeRight,
      };
      getQtdwaindex(params).then((res) => {
        this.tableRightList = res.data.data;
      });
    },
    initDivRight() {
      const self = this;
      const setInter = function () {
        if (
          self.$refs.contentInfo.scrollTop ===
          self.$refs.contentInfo.scrollHeight -
            self.$refs.contentInfo.clientHeight
        ) {
          self.$refs.contentInfo.scrollTop = 0;
        } else {
          self.$refs.contentInfo.scrollTop++;
        }
      };
      self.timer && clearInterval(self.timer);
      self.timer = setInterval(setInter, 100);

      this.$refs.contentInfo.addEventListener("mouseover", function () {
        self.timer && clearInterval(self.timer);
      });
      this.$refs.contentInfo.addEventListener("mouseout", function () {
        self.timer = setInterval(setInter, 100);
      });
    },
    //表格滚动
    initDiv() {
      // console.log("计算");

      const self = this;
      const setInter = function () {
        // console.log('this.$refs.contentInfoTable',self.$refs.contentInfoTable);
        if (
          self.$refs.contentInfoTable.scrollTop ===
          self.$refs.contentInfoTable.scrollHeight -
            self.$refs.contentInfoTable.clientHeight
        ) {
          self.$refs.contentInfoTable.scrollTop = 0;
        } else {
          self.$refs.contentInfoTable.scrollTop++;
        }
      };
      self.timer && clearInterval(self.timer);
      self.timer = setInterval(setInter, 100);

      this.$refs.contentInfoTable.addEventListener("mouseover", function () {
        self.timer && clearInterval(self.timer);
      });
      this.$refs.contentInfoTable.addEventListener("mouseout", function () {
        self.timer = setInterval(setInter, 100);
      });
    },
    tableRowClassName({ rowIndex }) {
      // console.log("row", row);

      if (rowIndex % 2 == 0) {
        return "";
      } else {
        return "warning-row";
      }
    },
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    // },

    dialogEvent(id, i) {
      this.currentIndex = i;
      // console.log("id", id);
      this.form.valueOption = "";
      this.form.valueOptionOne = "";
      this.qtdwid = id;
      this.dialogTableVisible = !this.dialogTableVisible;
      if (this.dialogTableVisible) {
        this.getRightDialog();
      }
    },
    // js的操作
    removeChild() {
      var p1 = window.document.getElementsByClassName("el-dialog__header")[0];
      // console.log("p1", p1);
      p1.remove();
    },

    getRecord() {
      // console.log("进入了");
      const params = {
        year: this.formYear.valueOption ? this.formYear.valueOption : "2023",
        orderType: this.orderType,
      };
      getIllegalRecord(params).then((res) => {
        this.tableList = res.data.data;
        // console.log("res重大列表", res);
      });
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  created() {
    this.getRecord();
    this.initYears(); // 获取年份
  },
  mounted() {
    this.initDiv();
    this.getRightTable();
    this.initDivRight();
    this.getData();

    this.drawChart();
    // console.log("111111111");

    // for (let index = 0; index < 20; index++) {
    //   console.log(
    //     "Math.round(Math.random()*5+5);",
    //     Math.floor(Math.random() * 4 + 5)
    //   );
    // }
    this.removeChild(); // 移除头部
  },
};
</script>
<style lang="scss"  scoped>
::v-deep .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev {
  background-color: #243660 !important;
}
.table-item:hover {
  background: url("../../assets/home/bg_item_selected.png") !important;
  background-size: 100% 100% !important;
}
.choose-item {
  background: url("../../assets/home/bg_item_selected.png") !important;
  background-size: 100% 100% !important;
}
.more-bored {
  width: 120px;
  height: 464px;
  position: absolute;
  right: 0px;
  bottom: 20px;
  // border: 1px solid red;
}
.btn-group-more {
  position: absolute;
  padding: 5px;
  background-color: #081f4f;
  right: 5px;
  bottom: 33px;
  cursor: pointer;
  .btn-group-name {
    width: 100px;
    height: 20px;
    background-color: #14315b;
    margin-bottom: 10px;
    color: #ffffff;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
  }
}
::v-deep .btn-prev {
  background-color: "#243660" !important;
}
.bg-id {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-size: cover; */
  background-position-y: 0px;
  background-position-x: 3px;
  background-size: 80% 100%;
  background-image: url("../../assets/home/icon_sort.png");
  background-repeat: no-repeat;
}
.btn-group-bottom {
  cursor: pointer;
  width: 122px;
  height: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  background-size: 100% 100%;
  background-image: url("../../assets/home/downLoad.png");
  background-repeat: no-repeat;
}
.main-bottom {
  width: 2080px;
  display: flex;
  position: absolute;
  bottom: 19px;
  flex-direction: row;
  justify-content: space-between;
}
.marTop {
  margin-top: 40px;
}
.color-btn {
  background: rgba(45, 196, 255, 0.8) !important;
}
.btn {
  background: rgba(20, 146, 255, 0.2);
  border-radius: 17.5px;
  width: 100px;
  height: 35px;
  line-height: 35px;
  margin: 0 10px;
  color: #cff0ff;
  border: none;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
}
.form-year {
  /deep/ .el-input__inner {
    width: 120px !important;
    background: #143372 !important;
    border: 1px solid #143372 !important;
    font-size: 21px !important;
  }
}
.select-year {
  position: absolute;
  /* top: 101px; */
  right: 263px;
  top: 20px;
  z-index: 99999;
  width: 10px;
  height: 10px;
}
.mySwitchWwc {
  width: 170px;
  height: 60px;
  background-image: url("../../assets/home/wancheng.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.mySwitchWc {
  width: 170px;
  height: 60px;
  background-image: url("../../assets/home/weiwancheng.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.mySwitchWc {
}
::v-deep .el-table,
.el-table__expanded-cell {
  background-color: transparent !important;
}
::v-deep .el-button {
  color: #666666 !important;
  padding: 4px 14px !important;
  position: relative !important;
  right: -317px !important;
  top: -13px !important;
}
::v-deep .el-input__inner {
  color: #ffffff !important;
}
/deep/ .el-table,
.el-table__expanded-cell {
  background-color: "#0a2149";
  // background-color: transparent;//这是设置透明背景色
}
::v-deep .el-table__empty-block {
  width: auto !important;
  min-height: 513px !important;
}
::v-deep .el-table__empty-text {
  line-height: 60px !important;
  width: 50% !important;
  color: #909399 !important;
}
.currentCount {
  background: #28a7e3 !important;
  color: #cff0ff !important;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.color {
  color: #29a8e4 !important;
}
::v-deep .el-table .el-table__cell {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #c7e0ec;
  overflow: hidden; //溢出隐藏
  -webkit-box-orient: vertical; //设置伸缩盒子对象的子元素的排列方式
  -webkit-line-clamp: 1; //设置 块元素包含的文本行数
}
.icon {
  // width: 40px;
  // height: 40px;
  // border: 1px solid red;
  position: absolute;
  .el-icon-caret-top {
    cursor: pointer;

    position: relative;
    right: -61px;
    top: -7px;
  }
  .el-icon-caret-bottom {
    cursor: pointer;

    position: relative;
    right: -41px;
    top: 6px;
  }
}
.icon-unit {
  // width: 40px;
  // height: 40px;
  // border: 1px solid red;
  position: absolute;
  .el-icon-caret-top {
    cursor: pointer;
    position: relative;
    right: -80px;
    top: -7px;
  }
  .el-icon-caret-bottom {
    cursor: pointer;

    position: relative;
    right: -60px;
    top: 6px;
  }
}
.chart {
  position: relative;
}
.bg-box {
  position: absolute;
  width: 900px;
  bottom: 57px;
  height: 33px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../assets/home/Rectangle 1 (1).png");
}
.main-content-center {
  display: flex;
  flex-direction: column;
  width: 901px;
  height: 780px;
  // border: 1px solid red;
  margin-left: 150px;
  margin-right: 170px;
  margin-top: 85px;

  #chart-panel {
    margin-top: 100px;
    width: 901px;
    height: 490px;
  }
  .info-title {
    font-family: "PangMenZhengDao";
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 39px;

    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;

    text-shadow: 0px 0px 10px #0085ea;
  }
  .center-top-box {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: row;
  }
  .center-top:last-child {
    margin-right: 0px;
  }
  .center-top {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 126px;
    height: 132px;
    margin-right: 60px;
    .center-data {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      margin-top: 8px;
    }
    .center-count {
      font-family: "Manrope3";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      color: #ffffff;
      text-shadow: 0px 0px 10px #0085ea;
    }
    .img {
      width: 100%;
      height: 55px;
    }
  }
}
::v-deep .is-active {
  z-index: -999 !important;
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.main-view {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  background-image: url("../../assets/home/bage-img.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .main-content {
    width: 100%;
    height: 100%;
    // padding: 0 50px;
    display: flex;
    flex-direction: row;
  }
  .main-content-left {
    margin-top: 55px;
    width: 374px;
    height: 700px;
    // border: 1px solid red;
    margin-left: 56px;
    .left-top-img {
      width: 220px;
      height: 68px;
      background-size: 100% 48px;
      background-repeat: no-repeat;
      background-position: bottom;
      background-image: url("../../assets/home/left-bage.png");
      .text {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        color: #cff0ff;
        margin-left: 24px;
      }
    }
    .table {
      margin-top: 29px;
      .table-title {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 10px;
        box-sizing: border-box;
        div {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          color: #9db3ce;
        }
        .id {
          width: 44px;
          height: 28px;
        }
        .name {
          width: 232px;
          height: 28px;
          text-align: center;
        }
        .title-count {
          width: 88px;
          height: 28px;
          font-weight: 400;
        }
      }
      .table-item {
        width: 100%;
        padding: 13.5px 10px;
        box-sizing: border-box;
        background: rgba(0, 133, 234, 0.05);
        margin-bottom: 4px;
        div {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          text-align: center;
        }
        .id {
          width: 37px;
          height: 28px;
          margin-right: 8px;
          color: #cff0ff;
        }
        .name {
          width: 232px;
          color: #cff0ff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
        .count {
          width: 88px;
          height: 28px;
          color: #f9ad25;
        }
      }
    }
  }
  // .main-content-center {
  //   display: flex;
  //   flex-direction: column;
  //   width: 901px;
  //   height: 780px;
  //   // border: 1px solid red;
  //   margin-left: 85px;
  //   margin-right: 96px;
  //   margin-top: 85px;

  //   #chart-panel {
  //     margin-top: 100px;
  //     width: 901px;
  //     height: 490px;
  //   }
  //   .info-title {
  //     font-family: "PangMenZhengDao";
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 34px;
  //     line-height: 39px;

  //     text-align: center;
  //     letter-spacing: 0.01em;
  //     color: #ffffff;

  //     text-shadow: 0px 0px 10px #0085ea;
  //   }
  //   .center-top-box {
  //     width: 100%;
  //     justify-content: center;
  //     display: flex;
  //     flex-direction: row;
  //   }
  //   .center-top:last-child {
  //     margin-right: 0px;
  //   }
  //   .center-top {
  //     // border: 1px solid red;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     width: 126px;
  //     height: 132px;
  //     margin-right: 60px;
  //     .center-data {
  //       font-family: "PingFang SC";
  //       font-style: normal;
  //       font-weight: 400;
  //       font-size: 18px;
  //       color: #ffffff;
  //       margin-top: 8px;
  //     }
  //     .center-count {
  //       font-family: "Manrope3";
  //       font-style: normal;
  //       font-weight: 700;
  //       font-size: 32px;
  //       color: #ffffff;
  //       text-shadow: 0px 0px 10px #0085ea;
  //     }
  //     .img {
  //       width: 100%;
  //       height: 55px;
  //     }
  //   }
  // }
  .main-content-center-two {
    display: flex;
    // overflow: hidden;
    // overflow:scroll;
    flex-direction: column;
    width: 1036px;
    height: 780px;
    // border: 1px solid red;
    margin-left: 103px;
    margin-right: 81px;
    margin-top: 66px;
    // .btn-group-title{
    //   width: 438px;
    // }
    // .mySwitch {
    //   .el-switch__label--left {
    //     position: relative;
    //     left: 70px;
    //     color: #fff;
    //     z-index: -1111;
    //     span {
    //       width: 40px;
    //     }
    //   }
    //   .el-switch__label--right {
    //     position: relative;
    //     right: 70px;
    //     color: #fff;
    //     z-index: -1111;
    //     span {
    //       display: inline-block;
    //       width: 40px;
    //     }
    //   }
    //   .el-switch__core {
    //     width: 170px !important;
    //     height: 60px !important;
    //     border-radius: 80px !important;
    //   }
    //   .el-switch__core:after {
    //     width: 46px !important;
    //     height: 46px !important;
    //   }
    // //  .el-switch__core::after {
    // //     left: 80% !important;
    // //   }
    //   .el-switch__label--right.is-active {
    //     z-index: 10;
    //     color: #fff !important;
    //   }
    //   .el-switch__label--left.is-active {
    //     z-index: 10;
    //     color: #9c9c9c !important;
    //   }
    // }

    .switch {
      margin-top: 70px;
      margin-left: 130px;
      margin-bottom: 97px;
    }
    // /deep/ .el-switch__core {
    //   width: 170px !important;
    //   height: 60px !important;
    //   border-radius: 80px !important;
    // }
    // /deep/ .el-switch__core:after {
    //   width: 46px !important;
    //   height: 46px !important;
    //   top: 6px !important;
    // }
    // >>> .el-switch.is-checked .el-switch__core::after {
    //   left: 83% !important;
    //   margin-left: -50px !important;
    // }
    .center-two-box {
      width: 1036px;
      height: 780px;
      margin-top: 57px;
      // width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background: rgba(7, 37, 99, 0.85);
      border: 1px solid #045eac;
      padding: 57px 22px;
      box-sizing: border-box;

      .btn-group-two {
        margin-right: 70px;
      }
    }
    .imgGroup {
      width: 17.58px;
      height: 20px;
    }
    .group-title {
      font-family: "PangMenZhengDao";
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      color: #ffffff;
      text-shadow: 0px 0px 10px #0085ea;
    }
    #chart-panel {
      margin-top: 100px;
      width: 901px;
      height: 490px;
    }
    .info-title {
      font-family: "PangMenZhengDao";
      font-style: normal;
      font-weight: 400;
      font-size: 34px;
      line-height: 39px;

      text-align: center;
      letter-spacing: 0.01em;
      color: #ffffff;

      text-shadow: 0px 0px 10px #0085ea;
    }
    .center-top-box {
      width: 100%;
      justify-content: center;
      display: flex;
      flex-direction: row;
    }
    .center-top:last-child {
      margin-right: 0px;
    }
    .center-top {
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 126px;
      height: 132px;
      margin-right: 60px;
      .center-data {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        margin-top: 8px;
      }
      .center-count {
        font-family: "Manrope3";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        color: #ffffff;
        text-shadow: 0px 0px 10px #0085ea;
      }
      .img {
        width: 100%;
        height: 55px;
      }
    }
  }
  .main-content-center-three {
    display: flex;
    flex-direction: column;
    width: 1036px;
    height: 780px;
    border: 1px solid red;
    margin-left: 18px;
    margin-right: 18px;
    margin-top: 66px;
    // .btn-group-title{
    //   width: 438px;
    // }
    .center-info {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .info-title {
      width: 825px;
      height: 78px;
      font-family: "PangMenZhengDao";
      font-style: normal;
      font-weight: 400;
      font-size: 34px;
      line-height: 39px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #ffffff;

      text-shadow: 0px 0px 10px #0085ea;
    }
    .info-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 30px 21px;
      box-sizing: border-box;
      .mar-right {
        margin-right: 44px;
      }

      #charts-Pie {
        width: 482px;
        height: 275px;
      }
    }
  }
  .main-content-center-four {
    display: flex;
    flex-direction: column;
    width: 1036px;
    height: 780px;
    // border: 1px solid red;
    margin-left: 18px;
    margin-right: 18px;
    margin-top: 66px;
    // .btn-group-title{
    //   width: 438px;
    // }
    .info-four {
      padding: 30px 25px;
      box-sizing: border-box;
    }
    .center-info {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .info-title {
      width: 825px;
      height: 78px;
      font-family: "PangMenZhengDao";
      font-style: normal;
      font-weight: 400;
      font-size: 34px;
      line-height: 39px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #ffffff;

      text-shadow: 0px 0px 10px #0085ea;
    }
    .info-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 30px 21px;
      box-sizing: border-box;
      .mar-right {
        margin-right: 44px;
      }

      #charts-Pie {
        width: 482px;
        height: 275px;
      }
    }
  }
  .main-content-center-table {
    width: 964px;
    height: 831px;
    background-image: url("../../assets/home/image 8.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 71px 22px 0 22px;
    position: absolute;
    top: -113px;
    .page {
      // width: 100%;
      margin-top: 50px;
      float: right;
    }
  }
  .main-content-right {
    width: 374px;
    height: 700px;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .right-title {
      margin-top: 62px;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      color: #cff0ff;
    }
    .btn-group {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 36px;
      div {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #cff0ff;
        width: 164px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background: #12448d;
        border-radius: 61px;
      }
      .count {
        margin-right: 20px;
      }
    }
    .table {
      margin-top: 29px;
      .table-title {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 10px;
        box-sizing: border-box;
        div {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          color: #9db3ce;
        }
        .id {
          width: 83px;
          height: 28px;
          margin-right: 10px;
          margin-left: 10px;
        }
        .name {
          width: 178px;
          height: 28px;
          // text-align: center;
        }
        .title-count {
          width: 83px;
          height: 28px;
          font-weight: 400;
        }
      }
      .table-item {
        width: 100%;
        padding: 13px 10px;
        box-sizing: border-box;
        background: rgba(0, 133, 234, 0.05);
        margin-bottom: 4px;
        div {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          height: 29px;
        }
        .id {
          width: 83px;
          height: 28px;
          color: #cff0ff;
          margin-right: 18px;
          display: flex;
          justify-content: center;
        }
        .name {
          width: 178px;
          color: #cff0ff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
        .count {
          width: 83px;
          height: 28px;
          color: #f9ad25;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
::v-deep .el-dialog {
  background: #fff0 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0) !important;
}
::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-table__body {
  width: auto !important;
}
::v-deep .el-table tr {
  background-color: #102954 !important;
}
::v-deep .el-table__header {
  width: auto !important;
  height: 44px !important;
}
// 表格内背景颜色
::v-deep .el-table .cell {
  height: 23px !important;
  display: -webkit-box !important; //对象作为弹性伸缩盒子模型显示
  overflow: hidden !important; //溢出隐藏
  -webkit-box-orient: vertical !important; //设置伸缩盒子对象的子元素的排列方式
  -webkit-line-clamp: 1 !important; //设置 块元素包含的文本行数
}
::v-deep .el-table__body tbody th,
::v-deep .el-table__body tbody tr,
::v-deep .el-table__body tbody td {
  background-color: #14315b !important;
  border: 0px;
  color: #c7e0ec; // 修改字体颜色
  font-size: 14px;
  height: 30px !important;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
  margin-top: 10px !important;
  overflow: hidden !important; //溢出隐藏
  -webkit-box-orient: vertical !important; //设置伸缩盒子对象的子元素的排列方式
  -webkit-line-clamp: 1 !important; //设置 块元素包含的文本行数
}
//去除表格每一行的下标线；
/deep/.el-table td {
  border-bottom: none;
}
//去除表头的下标线；
/deep/.el-table th.is-leaf {
  border-bottom: none;
}
::v-deep .el-table__body {
  -webkit-border-vertical-spacing: 5px;
}
::v-deep .el-table__row.warning-row {
  background: #102954 !important;
}
// ::v-deep .el-table td {
//   background: #102954 !important;
// }
// 修改高亮当前行颜色
::v-deep .el-table tbody tr:hover > td {
  background: #054694 !important;
}
// 取消当前行高亮--此时不能进行行点击操作
// ::v-deep .el-table tbody tr {
//   pointer-events: none;
// }
::v-deep .el-form-item__label {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  color: #ffffff !important;
}
::v-deep .el-input__inner {
  width: 167px !important;
  height: 28px !important;
  background: #191e28 !important;
  border: 1px solid #1b2b46 !important;
  border-radius: 4px !important;
}
.close {
  width: 20px;
  height: 20px;
  // border: 1px solid red;
  position: absolute;
  right: 105px;
  z-index: 999;
  top: -59px;
  cursor: pointer;
}
</style>