import axios from 'axios';

const request = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? 'https://jsstz.828ol.com/api' : 'https://jsstz.828ol.com/api',
    timeout: 60000,
    retry: 3, // 请求次数,
    retryInterval: 1000 // 请求超时后，1s再次请求
})

//统计违法情况
// export const getIllegalRecord = (data) => {
//     console.log('data',data);
//     return request({
//       url: '/api/blade-datav/admsxsjztqzsjssfrontaction/action/list',
//       method: 'get',
//       data:data
//     })
//   }
export const getIllegalRecord = (params) => {
    return request({
      url: '/blade-datav/admsxsjztqzsjssfrontaction/action/list',
      method: 'get',
      params: {
        ...params
      }
    })
  }
  export const getQtdwaindex = (params) => {
    return request({
      url: '/blade-datav/admsxsjztqzsjssfrontaction/qtdwaindex/list',
      method: 'get',
      params: {
        ...params
      }
    })
  }


  export const getActionindex = (params) => {
    return request({
      url: '/blade-datav/admsxsjztqzsjssfrontaction/actionindex/page',
      method: 'get',
      params: {
        ...params
      }
    })
  }
  export const getCount = (params) => {
    return request({
      url: '/blade-datav/admsxsjztqzsjssfrontaction/count',
      method: 'get',
      params: {
        ...params
      }
    })
  }
  export const getList = (params) => {
    return request({
      url: '/blade-datav/admsxsjztqzsjssfrontaction/actionindexdept/list',
      method: 'get',
      params: {
        ...params
      }
    })
  }
  export const getcountbydeptList = (params) => {
    return request({
      url: '/blade-datav/admsxsjztqzsjssfrontaction/countbydept/list',
      method: 'get',
      params: {
        ...params
      }
    })
  }
  export const getIndexdetail = (params) => {
    return request({
      url: '/blade-datav/admsxsjztqzsjssfrontaction/action/indexdetail',
      method: 'get',
      params: {
        ...params
      }
    })
  }
//   export const getIllegalRecord = (data) => {
//       return request({
//         url: '/api/blade-datav/admsxsjztqzsjssfrontaction/action/list',
//         method: 'get',
//         data:data
//       })
//     }
//   //统计抄告单情况
// export const getCopyCount = (id) => {
//   return request({
//     url: '/api/ylEnforcementLawRecord/getCopyCount?type='+id,
//     method: 'get',
//   })
// }
//  //人车企统计违法次数
//  export const getPeopleCarCompanyLawCount = (data) => {
//   return request({
//     url: '/api/ylEnforcementLawRecord/getPeopleCarCompanyLawCount',
//     method: 'get',
//     data:data
//   })
// }
//  //人车企统计违法次数
//  export const getTotalCount = (id) => {
//   return request({
//     url: '/api/ylEnforcementLawRecord/getTotalCount?type='+id,
//     method: 'get',
//   })
// }
//  //执法情况分析,查询不同地区的违法事件和抄报单数量
//  export const getAmountCountry = (id) => {
//   return request({
//     url: '/api/ylCopyOrder/getAmountCountry?area='+id,
//     method: 'get',
//   })
// }
//  //执法情况分析,查询不同地区的违法事件和抄报单数量
//  export const getAmountCountryCity = (data) => {
//   return request({
//     url: '/api/ylCopyOrder/getAmountCountry',
//     method: 'get',
//     data:data
//   })
// }
//  //重大执法记录
//  export const getBigRecord = (data) => {
//   return request({
//     url: '/api/ylEnforcementLawRecord/getBigRecord',
//     method: 'get',
//     data:data
//   })
// }

//  //抄告提醒记录
//  export const getRemindRecord = (data) => {
//   return request({
//     url: '/api/ylEnforcementLawRecord/getRemindRecord',
//     method: 'get',
//     data:data
//   })
// }
//  //办件量分析
//  export const getDoThingOCunt = (data) => {
//   return request({
//     url: '/api/ylEnforcementLawRecord/getDoThingOCunt',
//     method: 'get',
//     data:data
//   })
// }

// //各市执法记录信息
//  export const everyCityEnforcementLawRecord = (data) => {
//   return request({
//     url: '/api/ylCopyOrder/everyCityEnforcementLawRecord',
//     method: 'get',
//     data:data
//   })
// }

// //各市执法记录
// export const cityEnforcementLawRecord = (data) => {
//   return request({
//     url: '/api/ylCopyOrder/cityEnforcementLawRecord',
//     method: 'get',
//     data:data
//   })
// }
// //
// //执法涉人
// export const enforcementLawPeople = (data) => {
//   return request({
//     url: '/api/ylCopyOrder/enforcementLawPeople',
//     method: 'get',
//     data:data
//   })
// }
// //执法涉车
// export const enforcementLawCar = (data) => {
//   return request({
//     url: '/api/ylCopyOrder/enforcementLawCar',
//     method: 'get',
//     data:data
//   })
// }
// //区域查数量
// export const selTotal = (data) => {
//   return request({
//     url: '/api/ylLicensePlate/selTotal?country='+ data,
//     method: 'get',
//     // data:data
//   })
// }


// //市级区域查数量
// export const selCityTotal = (data) => {
//   return request({
//     url: '/api/ylLicensePlate/selCityTotal?code='+ data,
//     method: 'get',
//     // data:data
//   })
// }
// //查询各市含有违法记录的城市
// export const selCity = (data) => {
//   return request({
//     url: '/api/ylLicensePlate/selCity',
//     method: 'get',
//     data:data
//   })
// }